import React from "react"
import {Icon} from "./Icon";

export const InfoBox = ({icon, title, children}) => {
    return (
        <div className="icon-box icon-box-info w-100">
            <i><Icon icon={icon}/></i>
            <div className="icon-box-content">
                <h4>{title}</h4>
                {children}
            </div>
        </div>
    )
};
