import React from "react"
import {Layout} from "../components/Layout/layout";
import {Col, Container, Row} from "reactstrap";
import {HomeCarousel} from "../components/HomeCarousel";
import {graphql} from "gatsby";
import {InfoBox} from "../components/infocard";
import {MDXRenderer} from "gatsby-plugin-mdx";

export default ({data}) => (
    <React.Fragment>
        <Layout pageName="home">
            {data.carousel.edges.length > 0 && (<HomeCarousel items={data.carousel}/>)}
            <br/>
            <Container>
                <Row>
                    <Col xs={12} md={8}>
                        {data.announcements.edges.map(item => (
                            <InfoBox key={item.node.id} icon={item.node.frontmatter.icon} title={item.node.frontmatter.title}>
                                <MDXRenderer>{item.node.body}</MDXRenderer>
                            </InfoBox>
                        ))}
                    </Col>
                    <Col xs={12} md={4}>
                        {data.reminders.edges.map(item => (
                            <InfoBox key={item.node.id} icon={item.node.frontmatter.icon} title={item.node.frontmatter.title}>
                                <MDXRenderer>{item.node.body}</MDXRenderer>
                            </InfoBox>
                        ))}
                    </Col>
                </Row>
            </Container>
        </Layout>
    </React.Fragment>
)

export const query = graphql`
    query {
        announcements: allMdx(
            sort: {
                fields: [frontmatter___sort]
            }, 
            filter: {
                fileAbsolutePath: {
                    regex: "/home/announcements/"
                }, 
                frontmatter: {
                    enabled: { 
                        eq: true
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        icon
                        enabled
                        sort
                    }
                    body
                }
            }
        }

        carousel: allMdx(
            sort: {
                fields: [frontmatter___sort]
            }, 
            filter: {
                fileAbsolutePath: {
                    regex: "/home/carousel/"
                }, 
                frontmatter: {
                    enabled: { 
                        eq: true
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        enabled
                        sort
                        imageUrl
                    }
                    body
                }
            }
        }

        reminders: allMdx(
            sort: {
                fields: [frontmatter___sort]
            }, 
            filter: {
                fileAbsolutePath: {
                    regex: "/home/reminders/"
                }, 
                frontmatter: {
                    enabled: { 
                        eq: true
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        icon
                        enabled
                        sort
                    }
                    body
                }
            }
        }
    }
`;
